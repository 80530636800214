import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import JobSelect from '@app/src/Components/Common/JobSelect/JobSelect';
import CloseIcon from '@app/src/assets/close.svg?react';
import '@app/src/Components/TaxProfile/ManageJobsPanel.scss';

export const ManageJobsPanel = ({ open, onClose, value, allJobs, topJobCategories, onSave }) => {
  const [selectedJobs, setSelectedJobs] = useState(value);

  // `value` is often not yet loaded on component mount, so this ensures that
  // selectedJobs is always set to the value passed in
  useEffect(() => {
    if (open) {
      setSelectedJobs(value.filter((job) => job.earner === 'ME'));
    }
  }, [open, value]);

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle
        className='dialog-title'
        style={{
          paddingBottom: 0,
          borderBottom: 'none'
        }}
      >
        <div className='dialog-title-child'>
          <span>Manage Jobs</span>
          <IconButton aria-label='close' edge='end' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <div className='dialog-divider' />
      <DialogContent className='dialog-content'>
        <JobSelect
          allJobs={allJobs}
          topJobCategories={topJobCategories}
          selectedJobs={selectedJobs}
          setSelectedJobs={setSelectedJobs}
          listProps={{ sx: { height: 283, marginBottom: 2 } }}
          origin='tax profile'
          emptyUntilFocused={false}
        />
        <Button
          variant='contained'
          color='secondary'
          fullWidth
          onClick={() => {
            onSave && onSave(selectedJobs);
            onClose();
          }}
        >
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ManageJobsPanel;
