import _ from 'lodash';
import { createSelector } from 'reselect';
import { baseApi, TAGS } from '@app/src/api/baseApi';
import { getFulfilledRequestData } from '@app/src/api/utils';
import { COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import { COLLECTION_TYPE__HOME } from '@app/src/taxflow/sections/home/homeConstants';
import { INCOME_COLLECTION_TYPES } from '@app/src/taxflow/sections/income/incomeConstants';
import { COLLECTION_TYPE__HOME_ADDRESS } from '@app/src/taxflow/sections/personal/constants/personalConstants';

// Api
const taxDataApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // TODO replace all references to in-state queryResults with this query
    getTaxData: builder.query({
      query: ({ year }) => ({
        url: `taxes/user-tax-data`,
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, { year }) => [{ type: TAGS.TAX_DATA, id: year }]
    }),
    updateTaxData: builder.mutation({
      query: ({ taxData, generateSharedCollectionId, year }) => ({
        url: `taxes/user-tax-data`,
        method: 'POST',
        body: { taxData, generateSharedCollectionId, year }
      }),
      invalidatesTags: (result, error, { taxData, year }) => {
        return _.chain(taxData)
          .map('coll_type')
          .uniq()
          .flatMap((collectionType) =>
            _.compact([
              { type: TAGS.TAX_DATA, id: year },
              collectionType === COLLECTION_TYPE__CAR && { type: TAGS.CAR_PILLS, id: year },
              collectionType === COLLECTION_TYPE__HOME && { type: TAGS.HOME_PILLS, id: year },
              [COLLECTION_TYPE__HOME, COLLECTION_TYPE__HOME_ADDRESS].includes(collectionType) && {
                type: TAGS.HOME_ADDRESS_PRESELECT_OPTIONS,
                id: year
              },
              collectionType === INCOME_COLLECTION_TYPES.FREELANCE && TAGS.JOBS
            ])
          )
          .concat(
            { type: TAGS.SUBMIT_WARNINGS, id: year },
            { type: TAGS.BULK_UPLOAD_PILLS, id: year },
            { type: TAGS.REVIEW_PILLS, id: year }
          )
          .value();
      },
      async onQueryStarted({ taxData, year }, { dispatch }) {
        const updatesGroupedByCollection = _.groupBy(taxData, 'coll_type');
        _.forEach(updatesGroupedByCollection, (updatedTaxData, collectionType) => {
          dispatch(
            taxDataApi.util.updateQueryData('getTaxData', { collectionType, year }, (taxData) => {
              return [
                ..._.differenceWith(taxData, updatedTaxData, (prev, update) => _.isMatch(prev, update)),
                ...updatedTaxData
              ];
            })
          );
        });
      }
    }),
    deleteTaxData: builder.mutation({
      query: ({ coll_type, coll_id, slug, year }) => ({
        url: `taxes/user-tax-data`,
        method: 'DELETE',
        body: { coll_type, coll_id, slug, year }
      }),
      invalidatesTags: (result, error, { coll_type, year }) => [
        { type: TAGS.TAX_DATA, id: year },
        { type: TAGS.SUBMIT_WARNINGS, id: year },
        { type: TAGS.BULK_UPLOAD_PILLS, id: year },
        { type: TAGS.REVIEW_PILLS, id: year },
        ...(coll_type === COLLECTION_TYPE__CAR ? [{ type: TAGS.CAR_PILLS, id: year }] : []),
        ...(coll_type === COLLECTION_TYPE__HOME
          ? [
              { type: TAGS.HOME_PILLS, id: year },
              { type: TAGS.HOME_ADDRESS_PRESELECT_OPTIONS, id: year }
            ]
          : []),
        ...(coll_type === INCOME_COLLECTION_TYPES.FREELANCE ? [TAGS.JOBS] : [])
      ],
      async onQueryStarted({ coll_type, coll_id, slug, year }, { dispatch }) {
        dispatch(
          taxDataApi.util.updateQueryData('getTaxData', { collectionType: coll_type, year }, (taxData) => {
            return _.filter(taxData, _.negate(_.matches(_.omit({ coll_type, coll_id, slug }, _.isUndefined))));
          })
        );
        dispatch(
          taxDataApi.util.updateQueryData('getBulkUploadPills', { year }, (bulkUploadPills) => {
            return _.isNil(slug)
              ? _.filter(bulkUploadPills, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
              : bulkUploadPills;
          })
        );
        dispatch(
          taxDataApi.util.updateQueryData('getCarPills', { year }, (carPills) => {
            return _.isNil(slug)
              ? _.filter(carPills, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
              : carPills;
          })
        );
        dispatch(
          taxDataApi.util.updateQueryData('getHomePills', { year }, (homePills) => {
            return _.isNil(slug)
              ? _.filter(homePills, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
              : homePills;
          })
        );
        dispatch(
          taxDataApi.util.updateQueryData('getHomeAddressPreselectOptions', { year }, (preselectOptions) => {
            return _.isNil(slug)
              ? _.filter(preselectOptions, _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id })))
              : preselectOptions;
          })
        );
        dispatch(
          taxDataApi.util.updateQueryData('getReviewPills', { year }, (reviewPills) => {
            return _.isNil(slug)
              ? reviewPills.map((section) => {
                  return {
                    ...section,
                    pills: _.filter(
                      section.pills,
                      _.negate(_.matches({ collectionType: coll_type, collectionId: coll_id }))
                    )
                  };
                })
              : reviewPills;
          })
        );
      }
    }),
    getUIStage: builder.query({
      query: ({ year }) => ({
        url: 'taxes/ui-stage',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.uiStage,
      providesTags: (result, error, { year }) => [{ type: TAGS.UI_STAGE, id: year }]
    }),
    updateUIStage: builder.mutation({
      query: ({ uiStage, year }) => ({
        url: 'taxes/ui-stage',
        method: 'POST',
        body: { uiStage, year }
      }),
      invalidatesTags: (result, error, { year }) => [{ type: TAGS.UI_STAGE, id: year }]
    }),
    getBulkUploadPills: builder.query({
      query: ({ year }) => ({
        url: 'taxes/bulk-upload-pills',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.bulkUploadPills,
      providesTags: (result, error, { year }) => [{ type: TAGS.BULK_UPLOAD_PILLS, id: year }]
    }),
    getBulkUploadSuggestionPills: builder.query({
      query: ({ year }) => ({
        url: 'taxes/bulk-upload-suggestion-pills',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.bulkUploadSuggestionPills,
      providesTags: (result, error, { year }) => [{ type: TAGS.BULK_UPLOAD_PILLS, id: year }]
    }),
    getCurrentQuestionnaireQuestion: builder.query({
      query: ({ year }) => ({
        url: 'questionnaire/get-current-question',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.question,
      providesTags: (result, error, { year }) => [{ type: TAGS.CURRENT_QUESTIONNAIRE_QUESTION, id: year }]
    }),
    getQuestionnaireProgress: builder.query({
      query: ({ year }) => ({
        url: 'questionnaire/get-progress',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, { year }) => [{ type: TAGS.QUESTIONNAIRE_PROGRESS, id: year }]
    }),
    getQuestionnaireSummaryPills: builder.query({
      query: ({ year }) => ({
        url: 'taxes/questionnaire-summary-pills',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.questionnaireSummaryPills,
      providesTags: (result, error, { year }) => [{ type: TAGS.QUESTIONNAIRE_SUMMARY_PILLS, id: year }]
    }),
    generateQuestionnaire: builder.mutation({
      query: ({ year }) => ({
        url: 'questionnaire/generate-questions',
        method: 'POST',
        body: { year }
      }),
      invalidatesTags: (result, error, { year }) => [
        { type: TAGS.CURRENT_QUESTIONNAIRE_QUESTION, id: year },
        { type: TAGS.QUESTIONNAIRE_SUMMARY_PILLS, id: year },
        { type: TAGS.QUESTIONNAIRE_PROGRESS, id: year }
      ]
    }),
    progressToNextQuestionnaireQuestion: builder.mutation({
      query: ({ maybeFollowUpQuestion, year }) => ({
        url: 'questionnaire/progress-to-next-question',
        method: 'POST',
        body: { maybeFollowUpQuestion, year }
      }),
      transformResponse: (response) => ({
        currentQuestion: _.get(response, ['data', 'currentQuestion']),
        questionnaireComplete: _.get(response, ['data', 'questionnaireComplete'], true)
      }),
      invalidatesTags: (result, error, { year }) => [
        { type: TAGS.CURRENT_QUESTIONNAIRE_QUESTION, id: year },
        { type: TAGS.QUESTIONNAIRE_SUMMARY_PILLS, id: year },
        { type: TAGS.QUESTIONNAIRE_PROGRESS, id: year }
      ]
    }),
    goBackToPreviousQuestionnaireQuestion: builder.mutation({
      query: ({ year }) => ({
        url: 'questionnaire/go-back-to-previous-question',
        method: 'POST',
        body: { year }
      }),
      transformResponse: (response) => ({
        currentQuestion: _.get(response, ['data', 'currentQuestion']),
        questionnaireExited: _.get(response, ['data', 'questionnaireExited'], true)
      }),
      invalidatesTags: (result, error, { year }) => [
        { type: TAGS.CURRENT_QUESTIONNAIRE_QUESTION, id: year },
        { type: TAGS.QUESTIONNAIRE_PROGRESS, id: year }
      ]
    }),
    getReviewPills: builder.query({
      query: ({ year }) => ({
        url: 'taxes/review-pills',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.reviewPills,
      providesTags: (result, error, { year }) => [{ type: TAGS.REVIEW_PILLS, id: year }]
    }),
    getSubmitWarnings: builder.query({
      query: ({ year }) => ({
        url: 'taxes/submit-warnings',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.submitWarnings,
      providesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_WARNINGS, id: year }]
    }),
    dismissSubmitWarning: builder.mutation({
      query: ({ slug, year }) => ({
        url: 'taxes/dismiss-submit-warning',
        method: 'POST',
        body: { slug, year }
      }),
      invalidatesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_WARNINGS, id: year }],
      async onQueryStarted({ slug, year }, { dispatch }) {
        dispatch(
          taxDataApi.util.updateQueryData('getSubmitWarnings', { year }, (submitWarnings) => {
            return _.filter(submitWarnings, ({ slug: warningSlug }) => warningSlug !== slug);
          })
        );
      }
    }),
    getSubmitIssues: builder.query({
      query: ({ year }) => ({
        url: 'taxes/submit-issues',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.submitIssues,
      providesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_ISSUES, id: year }]
    }),
    updateSubmitIssues: builder.mutation({
      query: ({ updatedIssues, year }) => ({
        url: 'taxes/update-submit-issues',
        method: 'POST',
        body: { updatedIssues, year }
      }),
      invalidatesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_ISSUES, id: year }],
      async onQueryStarted({ updatedIssues, year }, { dispatch }) {
        dispatch(
          taxDataApi.util.updateQueryData('getSubmitIssues', { year }, () => {
            return updatedIssues;
          })
        );
      }
    }),
    getSsnMatched: builder.query({
      query: () => ({
        url: 'taxes/get-submit-ssn-matched',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.response,
      providesTags: () => [TAGS.SSN_MATCHED]
    }),
    executeSsnMatchedCheck: builder.mutation({
      query: () => ({
        url: 'taxes/get-id-match',
        method: 'POST'
      }),
      transformResponse: (response) => response.data.response.result,
      invalidatesTags: () => [TAGS.SUBMIT_WARNINGS, TAGS.SSN_MATCHED, TAGS.ID_VERIFICATION_QUESTIONS]
    }),
    getIdVerificationQuestions: builder.query({
      query: () => ({
        url: 'taxes/get-id-verification-questions',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.response,
      providesTags: () => [TAGS.ID_VERIFICATION_QUESTIONS]
    }),
    answerIdVerificationQuestions: builder.mutation({
      query: ({ questions, answers }) => ({
        url: 'taxes/confirm-id',
        method: 'POST',
        body: { questions, answers }
      }),
      transformResponse: (response) => response.data.response,
      invalidatesTags: () => [TAGS.ID_VERIFICATION_RESULT]
    }),
    getIdVerificationResult: builder.query({
      query: () => ({
        url: 'taxes/get-id-verification-result',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.response,
      providesTags: () => [TAGS.ID_VERIFICATION_RESULT]
    }),
    getTaxAmounts: builder.query({
      query: ({ year }) => ({
        url: 'taxes/get-tax-amounts',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.taxAmounts,
      providesTags: (result, error, { year }) => [{ type: TAGS.TAX_AMOUNTS, id: year }]
    }),
    getSubmitTimestamp: builder.query({
      query: ({ year }) => ({
        url: 'taxes/submit-timestamp',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.submitTimestamp,
      providesTags: (result, error, { year }) => [{ type: TAGS.SUBMIT_TIMESTAMP, id: year }]
    }),
    getReturnStatus: builder.query({
      query: ({ year }) => ({
        url: 'taxes/return-status',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, { year }) => [{ type: TAGS.RETURN_STATUS, id: year }]
    }),
    getCarPills: builder.query({
      query: ({ year }) => ({
        url: 'taxes/car-pills',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.carPills,
      providesTags: (result, error, { year }) => [{ type: TAGS.CAR_PILLS, id: year }]
    }),
    getHomePills: builder.query({
      query: ({ year }) => ({
        url: 'taxes/home-pills',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.homePills,
      providesTags: (result, error, { year }) => [{ type: TAGS.HOME_PILLS, id: year }]
    }),
    calculateTaxEstimate: builder.query({
      query: (body) => ({ url: 'taxes/calculate-tax-estimate', method: 'POST', body }),
      providesTags: () => [TAGS.TAX_ESTIMATE],
      transformResponse: (response) => response.data
    }),
    getHomeAddressPreselectOptions: builder.query({
      query: ({ year }) => ({
        url: 'taxes/home-address-preselect-options',
        method: 'GET',
        params: { year }
      }),
      transformResponse: (response) => response.data.options,
      providesTags: (result, error, { year }) => [{ type: TAGS.HOME_ADDRESS_PRESELECT_OPTIONS, id: year }]
    }),
    getCollectionTypeItems: builder.query({
      query: () => ({
        url: 'taxes/collection-type-items',
        method: 'GET'
      }),
      transformResponse: (response) => response.data.collectionTypeItems
    }),
    getJobs: builder.query({
      query: () => ({
        url: 'taxes/jobs',
        method: 'GET'
      }),
      transformResponse: (response) => response.data,
      providesTags: () => [TAGS.JOBS]
    }),
    createJob: builder.query({
      query: (payload) => ({
        url: 'taxes/job',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: () => [TAGS.JOBS]
    }),
    updateJob: builder.query({
      query: (payload) => ({
        url: 'taxes/job',
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: () => [TAGS.JOBS]
    })
  })
});

// Actions

export const getTaxData =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getTaxData.initiate({ year }),
      dispatch
    });

export const updateTaxData =
  ({ taxData, generateSharedCollectionId, year }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.updateTaxData.initiate({ taxData, generateSharedCollectionId, year }));
  };

export const deleteTaxData =
  ({ coll_type, coll_id, slug, year }) =>
  async (dispatch) => {
    await dispatch(taxDataApi.endpoints.deleteTaxData.initiate({ coll_type, coll_id, slug, year }));
  };

export const getUIStage =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getUIStage.initiate({ year }),
      dispatch
    });

export const updateUIStage =
  ({ uiStage, year }) =>
  async (dispatch) =>
    dispatch(taxDataApi.endpoints.updateUIStage.initiate({ uiStage, year }));

export const getBulkUploadPills =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getBulkUploadPills.initiate({ year }),
      dispatch
    });

export const getCurrentQuestionnaireQuestion =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getCurrentQuestionnaireQuestion.initiate({ year }),
      dispatch
    });

export const getQuestionnaireSummaryPills =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getQuestionnaireSummaryPills.initiate({ year }),
      dispatch
    });

export const getSsnMatched = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getSsnMatched.initiate, dispatch });

export const answerIdVerificationQuestions =
  ({ questions, answers }) =>
  async (dispatch) =>
    await dispatch(taxDataApi.endpoints.answerIdVerificationQuestions.initiate({ questions, answers }));

export const getIdVerificationQuestions = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getIdVerificationQuestions.initiate, dispatch });

export const getIdVerificationResult = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: taxDataApi.endpoints.getIdVerificationResult.initiate, dispatch });

export const getCarPills =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getCarPills.initiate({ year }),
      dispatch
    });

export const getHomePills =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getHomePills.initiate({ year }),
      dispatch
    });

export const getHomeAddressPreselectOptions =
  ({ year }) =>
  async (dispatch) =>
    getFulfilledRequestData({
      initiateFunction: () => taxDataApi.endpoints.getHomeAddressPreselectOptions.initiate({ year }),
      dispatch
    });

export const getJobs = () => async (dispatch) =>
  getFulfilledRequestData({
    initiateFunction: taxDataApi.endpoints.getJobs.initiate,
    dispatch
  });

export const createJob = (payload) => async (dispatch) => {
  await dispatch(taxDataApi.endpoints.createJob.initiate(payload));
};

export const updateJob = (payload) => async (dispatch) => {
  await dispatch(taxDataApi.endpoints.updateJob.initiate(payload));
};

// Hooks
export const {
  useGetTaxDataQuery,
  useUpdateTaxDataMutation,
  useGetUIStageQuery,
  useLazyGetUIStageQuery,
  useUpdateUIStageQuery,
  useGetBulkUploadPillsQuery,
  useGetBulkUploadSuggestionPillsQuery,
  useGetCurrentQuestionnaireQuestionQuery,
  useGetQuestionnaireSummaryPillsQuery,
  useGetQuestionnaireProgressQuery,
  useGenerateQuestionnaireMutation,
  useGoBackToPreviousQuestionnaireQuestionMutation,
  useGetReviewPillsQuery,
  useGetSubmitWarningsQuery,
  useDismissSubmitWarningMutation,
  useGetSubmitIssuesQuery,
  useGetCarPillsQuery,
  useUpdateSubmitIssuesMutation,
  useLazyGetSsnMatchedQuery,
  useLazyGetIdVerificationQuestionsQuery,
  useLazyGetIdVerificationResultQuery,
  useExecuteSsnMatchedCheckMutation,
  useGetTaxAmountsQuery,
  useLazyGetTaxAmountsQuery,
  useGetSubmitTimestampQuery,
  useGetReturnStatusQuery,
  useCalculateTaxEstimateQuery,
  useGetHomePillsQuery,
  useGetHomeAddressPreselectOptionsQuery,
  useGetJobsQuery,
  useGetCollectionTypeItemsQuery
} = taxDataApi;

export const jobsSelector = createSelector([taxDataApi.endpoints.getJobs.select()], ({ data: jobs }) => jobs || []);

export default taxDataApi;
