import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { Briefcase } from 'iconsax-react';
import { connect } from 'react-redux';
import InformationBanner from '@app/src/Components/Common/InformationBanner/InformationBanner';
import SearchList from '@app/src/Components/Common/SearchList/SearchList';
import { trackActivity } from '@app/src/services/analyticsService';

export const JobSelect = ({
  allJobs,
  selectedJobs,
  setSelectedJobs,
  origin,
  style = { margin: 'auto' },
  emptyUntilFocused = true,
  listProps,
  ...props
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [listItems, setListItems] = useState([]);
  const [inputFocused, setInputFocused] = useState(!emptyUntilFocused);

  const jobsBySlug = useMemo(() => _.keyBy(allJobs, 'slug'), [allJobs]);

  const [warning, setWarning] = useState(null); // {jobName: string, warningType: 'reported-income'  | null}

  const handleAddJob = (job) => {
    if (
      job.earner === 'ME' &&
      !selectedJobs.some((selectedJob) => selectedJob.slug === job.slug && selectedJob.name === job.name)
    ) {
      setSelectedJobs([job, ...selectedJobs]);
    }
  };

  const handleDeleteOrWarning = (job) => {
    if (job.earner !== 'ME') return;

    if (job.removable) {
      setSelectedJobs(
        selectedJobs.filter((selectedJob) => selectedJob.name !== job.name && selectedJob.earner === 'ME')
      );
    } else {
      setWarning({ jobName: job.name, warningType: 'reported-income' });
    }
  };

  const formatJobItem = useCallback(
    (job) => {
      const slug = _.get(job, 'slug', null);
      const name = _.get(job, 'name', slug);
      const earner = _.get(job, 'earner', 'ME');
      const id = _.get(job, 'id', name);
      const hasAssociatedIncome = _.get(job, 'hasAssociatedIncome', false);
      const contentfulJob = jobsBySlug?.[slug];
      return {
        id,
        slug,
        name: contentfulJob?.name ?? name,
        iconUrl: contentfulJob?.icon_url ?? null,
        earner,
        hasAssociatedIncome,
        showInList: true,
        checked: selectedJobs.some((selectedJob) => selectedJob.slug === slug || selectedJob.name === name),
        pillOrder: selectedJobs.findIndex((selectedJob) => selectedJob.slug === slug || selectedJob.name === name),
        removable: !hasAssociatedIncome
      };
    },
    [jobsBySlug, selectedJobs]
  );

  // Top job catetegories are the jobs to show if the search query is empty for a nicer default experience
  // This is pulled from a hardcoded list of slugs, which then finds the corresponding jobs in the contentful object
  // But for an onboarded user, the top job categories may include selected jobs as well. Some of these
  // jobs may have reported income, and thus be non-removable. So we need to unify the selected jobs into this list.
  const topJobCategories = useMemo(() => {
    return props?.topJobCategories
      .map((topJobCategory) => {
        const selectedJob = selectedJobs.find((selectedJob) => selectedJob.slug === topJobCategory?.slug);
        return {
          ...topJobCategory,
          hasAssociatedIncome: selectedJob?.hasAssociatedIncome
        };
      })
      .map(formatJobItem);
  }, [formatJobItem, props.topJobCategories, selectedJobs]);

  const listHeaderText = !searchQuery.trim().length
    ? inputFocused
      ? 'Frequently selected jobs:'
      : `e.g. “healthcare professional”, or “consultant”`
    : null;
  const selectedItems = useMemo(() => selectedJobs.map((job) => formatJobItem(job)), [selectedJobs, formatJobItem]);

  const debouncedAnalytics = useRef(
    _.debounce((val) => {
      trackActivity('search jobs', { search_text: val.toLowerCase(), origin });
    }, 300)
  ).current;

  const handleSearch = (search) => {
    setSearchQuery(search);
    if (search) debouncedAnalytics(search);
  };

  const fuse = useMemo(
    () =>
      new Fuse(
        allJobs.filter((job) => job.name !== 'Other'),
        { keys: ['name', 'synonymsForThisJobType'], threshold: 0.5 }
      ),
    [allJobs]
  );

  useEffect(() => {
    const customJobName = _.upperFirst(searchQuery.toLowerCase());
    // If the custom job searched matches an existing selected job,
    // then when we show it in the list, we need to unify the
    // hasAssociatedIncome property with the selected job
    const selectedJobAndSearchExactMatch = selectedJobs.find((selectedJob) => selectedJob.name === customJobName);

    const customJob = {
      id: customJobName,
      slug: null,
      name: customJobName,
      iconUrl: null,
      showInList: true,
      removable: !selectedJobAndSearchExactMatch?.hasAssociatedIncome,
      earner: 'ME',
      hasAssociatedIncome: selectedJobAndSearchExactMatch?.hasAssociatedIncome,
      checked: selectedJobs.some((selectedJob) => selectedJob.name === customJobName)
    };

    if (!searchQuery.trim().length) {
      setListItems(inputFocused ? topJobCategories.map(formatJobItem) : []);
      return;
    }

    const searchMatches = fuse.search(searchQuery);
    const matchedItems = searchMatches.map(({ item }) => formatJobItem(item));

    // Check if the custom job name already matches an existing job
    // if so only display the default job
    const exactMatch = allJobs.some((job) => job.name.toLowerCase() === searchQuery.toLowerCase());

    // Add custom job at the end if there's no exact match
    setListItems(exactMatch ? matchedItems : [...matchedItems, customJob]);
  }, [searchQuery, topJobCategories, selectedJobs, inputFocused, fuse, formatJobItem, allJobs]);

  return (
    <>
      {warning?.warningType === 'reported-income' && (
        <InformationBanner
          className='warning-box'
          text={`You reported income for ${warning.jobName} in your tax return. Jobs with reported income cannot be removed.`}
          buttonText='View tax return'
          link='/tax-filing/home'
        />
      )}

      <SearchList
        items={listItems}
        listHeaderText={listHeaderText}
        onAddItem={handleAddJob}
        onRemoveItem={handleDeleteOrWarning}
        onFocus={() => setInputFocused(true)}
        selectedItems={selectedItems}
        onSearch={handleSearch}
        query={searchQuery}
        style={style}
        fallbackIcon={<Briefcase />}
        chipStyle='square'
        iconSize={16}
        iconStyling={{ color: 'rgba(0, 0, 0, 1)' }}
        {...listProps}
      />
    </>
  );
};

const ConnectedJobSelect = connect(null, {})(JobSelect);

export default ConnectedJobSelect;
