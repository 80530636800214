export const S3_COMPOSITE_FORMS_URL = 'https://kt-composite-form-samples.s3.us-east-2.amazonaws.com/';

export const CATEGORY_TYPE_ADD_MORE = 'add-more';
export const CATEGORY_TYPE_OPTIONS = 'options';
export const CATEGORY_TYPE_TEXT = 'text';
export const CATEGORY_TYPE_MONEY = 'money';
export const CATEGORY_TYPE_TWELVE_A = 'twelve-a';
export const CATEGORY_TYPE_BOX_14 = 'box-14';
export const CATEGORY_TYPE_TAXFLOW_JOB_CATEGORY = 'jobs';
export const CATEGORY_TYPE_TAXFLOW_INFO = 'info';
export const CATEGORY_TYPE_TAXFLOW_CALENDAR = 'calendar';
export const CATEGORY_TYPE_TAXFLOW_MULTI_OPTION = 'multi-option';
export const CATEGORY_TYPE_TAXFLOW_FORM = 'form';
export const CATEGORY_TYPE_TAXFLOW_FORM_MULTI = 'form-multi';
export const CATEGORY_TYPE_TAXFLOW_FORM_TEXT = 'text';
export const CATEGORY_TYPE_TAXFLOW_FORM_EMAIL = 'email';
export const CATEGORY_TYPE_TAXFLOW_FORM_PHONE = 'phone';
export const CATEGORY_TYPE_TAXFLOW_FORM_NUMBER = 'number';
export const CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD = 'password';
export const CATEGORY_TYPE_TAXFLOW_FORM_DATE = 'date';
export const CATEGORY_TYPE_TAXFLOW_FORM_SSN = 'ssn';
export const CATEGORY_TYPE_TAXFLOW_FORM_SLIDER = 'slider';
export const CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN = 'dropdown';
export const CATEGORY_TYPE_TAXFLOW_ADDRESS = 'address';
export const CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD = 'form-upload';
export const CATEGORY_TYPE_TAXFLOW_FORM_CAPTURE = 'form-capture';
export const CATEGORY_TYPE_TAXFLOW_FORM_INPUT_DROPDOWN = 'form-input-summary';
export const CATEGORY_TYPE_TAXFLOW_PREFILL_LOADING = 'prefill-loading';
export const CATEGORY_TYPE_TAXFLOW_SYSTEM = 'system';
export const CATEGORY_TYPE_BOUNCE = 'bounce';
export const CATEGORY_TYPE_SUMMARY = 'summary';
export const CATEGORY_TYPE_STATE = 'state';
export const CATEGORY_TYPE_INITIAL = 'initial';
export const CATEGORY_TYPE_MILES = 'miles';
export const CATEGORY_TYPE_YEARS = 'years';
export const CATEGORY_TYPE_PERCENT = 'percent';
export const CATEGORY_TYPE_SQUARE_FOOTAGE = 'square-footage';
export const CATEGORY_TYPE_EIN = 'ein';
export const CATEGORY_TYPE_SSN_OR_EIN = 'ssn-or-ein';
export const CATEGORY_TYPE_ZIP = 'zip';
export const CATEGORY_TYPE_INT = 'int';
export const CATEGORY_TYPE_TEMPLATE = 'template';
export const CATEGORY_TYPE_DROPDOWN_SEARCH = 'dropdown-search';
export const CATEGORY_TYPE_VERIFY = 'verify';
export const CATEGORY_TYPE_BUSINESS_CODE = 'business-code';
export const CATEGORY_TYPE_EXPENSES_LINK = 'expenses-link';

export const TAXFLOW_SYSTEM_QUESTION_TYPE_CONNECT_BANK = 'connect-bank';

export const RETURN_URL_KEY_NAME = 'return-url';

export const REFERRER_STORAGE_KEY = 'referrer_2';
export const REFERRER_ID_STORAGE_KEY = 'referrer_id_2';
export const REFERRER_PARAM_KEY = 'referrer';
export const REFERRER_ID_PARAM_KEY = 'referrer_id';

export const Url_TAXFLOW_BASEURL = '/tax-filing/';
export const Url_LOGIN = 'login';
export const Url_INTRO_TAX_STATUS = 'intro-tax-status';

export const DEFAULT_WORK = '1099 work';

// expense review
export const EXPENSE_REVIEW_QUERY_AMOUNT = 100;
export const STATUS_FILTER_OPTIONS_LIST = [
  { status: 'Suggested', value: 'maybe' },
  { status: 'Deductions', value: 'yes' },
  { status: 'Not deductible', value: 'no' }
];
export const AMOUNT_FILTER_OPTIONS_LIST = [
  { amount: '$0 - $9.99', value: '0-9.99' },
  { amount: '$10 - $29.99', value: '10-29.99' },
  { amount: '$30 - $99.99', value: '30-99.99' },
  { amount: '$100+', value: '100-+' }
];
export const yesStatusesArr = ['probably', 'prob', 'yes'];
export const maybeStatusesArr = ['maybe', 'maybe*'];
export const noStatusesArr = ['no'];
export const EXPENSE_REVIEW_STATUS_OPTIONS_LIST = [
  { displayName: 'Deduction', value: 'yes' },
  { displayName: 'Not deductible', value: 'no' }
];

export const ACTIVE_USER_STATUSES = ['active', 'free trial', 'trialing'];

export const BUSINESS_CODES_SEARCH_LIST_MAX_ITEMS = 8;
export const OTHER_CATEGORY_ID = 6;
export const FUSE_CATEGORY_OPTIONS = {
  keys: ['displayName', { name: 'relatedTerms', weight: 0.9 }],
  threshold: 0.2,
  includeMatches: true
};

export const EXPENSE_CATEGORIES = {
  GAS: 1
};

// These are the potential people who could be earning 1099 income
export const WHO_1099 = {
  FILER: 'filer',
  SPOUSE: 'spouse',
  BOTH: 'both'
};

export const EXPENSE_UPLOAD_MANUAL_EXPENSE_BANNER_TITLE =
  'Lots of manual expenses? Try uploading a spreadsheet or statement.';

export const EXPENSE_UPLOAD_BANK_LIST_TITLE = `Can't link your account? Try uploading a spreadsheet or statement.`;

export const SUBSCRIPTION_TYPES = {
  ANNUAL: 'annual',
  FREE: 'free',
  MONTHLY: 'monthly',
  PREMIUM: 'premium'
};

export const ICON_URLS = {
  HELP_SUPPORT:
    '//images.ctfassets.net/xauw5r0xpp02/7cQany4RAZi0oQcEN6QOqJ/59ec7704181f976fffc9d7ab44897466/help-support.png',
  MAGIC_STAR:
    '//images.ctfassets.net/xauw5r0xpp02/3tlO2br9AWQRk9zUSzf0La/7a42d7d43e5699fd6151de0b01f4a8e5/magic-star.svg'
};

export const SQUARE_PILL_ACTION_ICON_TYPES = {
  REMOVE: 'REMOVE',
  QUESTION: 'QUESTION',
  NONE: null
};

/** Earliest supported year (expenses + filing ) */
export const MIN_SUPPORTED_YEAR = 2023;
