import React from 'react';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import CloseIcon from '@app/src/assets/close.svg?react';
import { useIsPremiumSubscriber } from '@app/src/hooks/pricingHooks';
import { uploadErrorSelector } from '@app/src/selectors/taxFlowSelectors';
import { TAXFLOW__BULK_UPLOAD_FAILED_HEADER } from '@app/src/taxflow/collection/constants/formUploadConstants';
import { hideCollectionFormUploadErrorModal } from '@app/src/taxflow/collection/services/collectionService';
import { getFormUploadErrorMessage } from '@app/src/taxflow/collection/utils/collectionUtils';
import {
  flattenedUploadAttemptsSelector,
  formUploadModalHeaderSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import '@app/src/taxflow/collection/components/TaxFlowCollectionFormUploadErrorModal.scss';

const UnsupportedFormMessage = ({ errorMessage }) => {
  const { data: isPremiumSubscriber, isLoading: isPremiumSubscriberLoading } = useIsPremiumSubscriber();

  if (isPremiumSubscriberLoading) {
    return null;
  }

  if (isPremiumSubscriber) {
    return (
      <p>
        Please email this form to your dedicated tax assistant or{' '}
        <a href='mailto:support@keepertax.com'>support@keepertax.com</a> to have it included in your tax return.
      </p>
    );
  }

  if (errorMessage === 'Form not supported by Keeper') {
    return (
      <>
        <p>Unfortunately, the basic subscription doesn't support filing for:</p>
        <ul>
          <li>Rental property income</li>
          <li>Income from a foreign country</li>
          <li>K-1 (partnership) income</li>
          <li>1099-C (cancellation of debt)</li>
        </ul>
        <p>
          Please reach out to <a href='mailto:support@keepertax.com'>support@keepertax.com</a> or your assigned tax
          assistant to upgrade to the Premium subscription to have a dedicated tax pro add any of these complex
          situations to your tax return.
        </p>
      </>
    );
  }

  if (errorMessage === 'Incorrect form provided') {
    return (
      <>
        <p>Unfortunately, Keeper currently doesn't support filing for:</p>
        <ul>
          <li>Rental property income</li>
          <li>Income from a foreign country</li>
          <li>K-1 (partnership) income</li>
          <li>1099-C (cancellation of debt)</li>
        </ul>
        <p>
          Please reach out to <a href='mailto:support@keepertax.com'>support@keepertax.com</a> or your assigned tax
          assistant for a partial refund if you have an unsupported form.
        </p>
      </>
    );
  }

  return null;
};

const TaxFlowCollectionFormUploadErrorModal = ({
  collectionFormUploadErrorModalAttemptId,
  header,
  uploadError,
  uploadAttempts,
  hideCollectionFormUploadErrorModal,
  year
}) => {
  const onClose = () => {
    hideCollectionFormUploadErrorModal();
  };
  const errorMessage = _.chain(uploadAttempts)
    .find({ id: collectionFormUploadErrorModalAttemptId })
    .get('errorMessage')
    .value();

  const errorBody = uploadError ?? getFormUploadErrorMessage({ errorMessage, isBulk: true, year });

  return (
    <Dialog className={'taxflow-collection-form-upload-error-modal'} onClose={onClose} open>
      <DialogTitle className={'form-upload-modal-header'}>
        <h1 className={'form-upload-modal-header-title'}>{header ?? TAXFLOW__BULK_UPLOAD_FAILED_HEADER}</h1>
        <IconButton aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className='steps-head'>
        <DialogContentText className='step-description'>
          <p>{errorBody}</p>
          <div>
            <UnsupportedFormMessage errorMessage={errorMessage}></UnsupportedFormMessage>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  collectionFormUploadErrorModalAttemptId: _.get(state, ['taxFlowModals', 'collectionFormUploadErrorModalAttemptId']),
  header: formUploadModalHeaderSelector(state),
  uploadError: uploadErrorSelector(state),
  uploadAttempts: flattenedUploadAttemptsSelector(state),
  year: yearSelector(state)
});

const mapDispatchToProps = {
  hideCollectionFormUploadErrorModal
};

const ConnectedTaxFlowCollectionFormUploadErrorModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowCollectionFormUploadErrorModal);

export default ConnectedTaxFlowCollectionFormUploadErrorModal;
