import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useGetFeaturesQuery } from '@app/src/api/profileApi';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const useTaxYearLifecycle = ({ taxYear } = {}) => {
  const selectedYear = useSelector(yearSelector);
  const year = _.defaultTo(taxYear, selectedYear);
  const { data: features, isLoading: featuresLoading } = useGetFeaturesQuery({ year });

  if (featuresLoading) {
    return { isLoading: true };
  }
  const isTaxFilingClosed = _.some(features, { name: `ty${year}-closed`, value: 1 });
  const isFullFilingOpen = _.some(features, { name: `ty${year}-full`, value: 1 }) && !isTaxFilingClosed;
  const isPartialFilingOpen = _.some(features, { name: `ty${year}-partial`, value: 1 }) && !isFullFilingOpen;
  const isBulkOnlyFilingOpen = _.some(features, { name: `ty${year}-bulk-only`, value: 1 }) && !isPartialFilingOpen;
  const isTaxFilingNotYetOpen =
    !isBulkOnlyFilingOpen && !isBulkOnlyFilingOpen && !isPartialFilingOpen && !isFullFilingOpen && !isTaxFilingClosed;

  return {
    isTaxFilingNotYetOpen,
    isBulkOnlyFilingOpen,
    isPartialFilingOpen,
    isFullFilingOpen,
    isTaxFilingClosed,
    isLoading: false
  };
};
